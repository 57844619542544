import { Tag } from 'antd';
import { SexEnum, StatusEnum } from 'src/api/types/common';
import { IFormSchema } from 'src/components/Form';
import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';

import styles from './index.module.less';

export const columns: QueryTableColumnProps = [
  {
    title: '用户ID',
    dataIndex: 'userId',
    width: 100,
  },
  {
    title: '用户名',
    dataIndex: 'username',
    key: 'username',
    width: 150,
  },
  {
    title: '姓名',
    dataIndex: 'nickName',
    key: 'nickName',
    width: 150,
  },
  {
    title: '手机号',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    width: 150,
  },
  {
    title: '所属部门',
    dataIndex: 'deptName',
    key: 'deptName',
    width: 150,
  },
  {
    title: '岗位',
    dataIndex: 'posts',
    key: 'posts',
    width: 200,
    render(posts: { id: string; name: string }[]) {
      return posts?.map((item) => (
        <Tag key={item.id} color="blue">
          {item.name}
        </Tag>
      ));
    },
  },
  {
    title: '角色',
    dataIndex: 'roles',
    key: 'roles',
    width: 200,
    render(roles: { id: string; name: string }[]) {
      return roles?.map((item) => (
        <Tag key={item.id} color="blue">
          {item.name}
        </Tag>
      ));
    },
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    width: 250,
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  username: {
    type: 'string',
    title: '用户名',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入用户名',
    },
  },
  nickName: {
    type: 'string',
    title: '姓名',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入姓名',
    },
  },
  phoneNumber: {
    type: 'string',
    title: '手机号',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入手机号',
      type: 'tel',
    },
  },
};

export const createSchema: IFormSchema = {
  layout: {
    type: 'void',
    'x-component': 'FormGrid',
    'x-component-props': {
      maxColumns: 2,
    },
    properties: {
      username: {
        type: 'string',
        title: '用户名',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入用户名',
          maxLength: 50,
        },
        'x-validator': [{ required: true, message: '请输入用户名' }],
      },
      password: {
        type: 'string',
        title: '密码',
        'x-component': 'Password',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入密码',
          checkStrength: true,
        },
        'x-validator': [{ required: true, message: '请输入密码' }],
      },
      phoneNumber: {
        type: 'string',
        title: '手机号',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          type: 'tel',
          placeholder: '请输入手机号',
        },
        'x-validator': [
          { required: true, message: '请输入手机号' },
          { format: 'phone', message: '请输入正确手机号' },
        ],
      },
      email: {
        type: 'string',
        title: '邮箱',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          type: 'email',
          placeholder: '请输入邮箱',
        },
        'x-validator': [{ format: 'email', message: '请输入正确邮箱' }],
      },
      nickName: {
        type: 'string',
        title: '姓名(昵称)',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入姓名',
        },
        'x-validator': [{ required: true, message: '请输入姓名' }],
      },
      roleIds: {
        type: 'array',
        title: '角色',
        'x-component': 'Select',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请选择角色',
          mode: 'multiple',
        },
        'x-validator': [{ required: true, message: '请选择角色' }],
      },
      deptId: {
        type: 'number',
        title: '部门',
        'x-component': 'TreeSelect',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请选择部门',
          treeDefaultExpandAll: true,
          popupClassName: styles.user_select_popup,
        },
        'x-validator': [{ required: true, message: '请选择部门' }],
      },
      postIds: {
        type: 'array',
        title: '岗位',
        'x-component': 'Select',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请选择岗位',
          mode: 'multiple',
        },
        'x-decorator-props': {
          tooltip: '选择部门后，则可选择岗位',
        },
        'x-validator': [{ required: true, message: '请选择岗位' }],
      },
      status: {
        type: 'string',
        title: '用户状态',
        'x-component': 'Radio.Group',
        'x-decorator': 'FormItem',
        'x-component-props': {
          options: [
            { value: StatusEnum.NORMAL, label: '启用' },
            { value: StatusEnum.STOP, label: '停用' },
          ],
        },
        default: StatusEnum.NORMAL,
        'x-decorator-props': {
          gridSpan: 1,
          asterisk: true,
        },
      },
      sex: {
        type: 'string',
        title: '性别',
        'x-component': 'Radio.Group',
        'x-decorator': 'FormItem',
        'x-component-props': {
          options: [
            { value: SexEnum.MAN, label: '男' },
            { value: SexEnum.WOMAN, label: '女' },
          ],
          buttonStyle: 'solid',
        },
        default: SexEnum.MAN,
        'x-decorator-props': {
          gridSpan: 2,
          asterisk: true,
        },
        'x-validator': [{ required: true, message: '请选择性别' }],
      },
      remark: {
        type: 'string',
        title: '备注',
        'x-component': 'Input.TextArea',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入备注',
          showCount: true,
          maxLength: 500,
          style: {
            height: 100,
          },
        },
        'x-decorator-props': {
          gridSpan: 4,
        },
      },
    },
  },
};
