import { useMemo, useState } from 'react';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { Button, message, Modal, Tree } from 'antd';
import type { TreeDataNode, TreeSelectProps } from 'antd';
import { useBoolean, useMemoizedFn, useRequest } from 'ahooks';
import { Input, DatePicker } from '@formily/antd-v5';
import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { deleteUser, getDeptList, getUserList } from 'src/api/clients/system';
import { schema, columns } from './schema';
import { IDeptItem, IGetUserListReq, IUserItem } from 'src/api/types/system';
import { QuerySceneEnum } from 'src/api/types/common';
import { UserModal } from './user-modal';

const QueryTableSystemUserName = 'SystemUsersManageTableName';

type SelectTreeDept = TreeDataNode & TreeSelectProps;

export default function SystemUsersManage() {
  const [open, { setTrue, setFalse }] = useBoolean(false);
  const [currentUser, setCurrentUser] = useState<IUserItem>();
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const getUserTableList = useMemoizedFn(async (params: IGetUserListReq) => {
    const result = await getUserList(params);
    const { count, rows } = result ?? {};
    return {
      data: rows,
      total: count,
    };
  });

  const { data: deptTree } = useRequest(async () => {
    const result = await getDeptList({
      offset: 0,
      limit: 10000,
      scene: QuerySceneEnum.TREE,
    });
    const keys = [] as number[];
    const transformData = (data: IDeptItem): SelectTreeDept | null => {
      const result: SelectTreeDept = {
        key: Number(data?.deptId),
        title: data.deptName ?? '',
        value: Number(data?.deptId),
      };
      if (data.children) {
        keys.push(Number(data.deptId));
        result.children = data.children?.map(transformData).filter(Boolean) as SelectTreeDept[];
      }
      return result;
    };
    const tree = result?.map(transformData) as SelectTreeDept[];
    setExpandedKeys(keys);
    return tree;
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      QueryTableSystemUserName,
    );
  });

  const onDeleteUserByIds = useMemoizedFn(async (ids: number[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定用户吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteUser(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 100,
        renderType: 'Operation',
        renderProps: (col: IUserItem) => {
          return {
            buttons: [
              {
                label: '编辑',
                onClick: () => {
                  setCurrentUser(col);
                  setTrue();
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteUserByIds([col.userId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <div className="m-4 rounded-md flex gap-4">
      <div className="bg-white w-1/4 xl:block 2xl:block hidden md:hidden sm:hidden rounded-md p-4">
        <div className="text-base font-semibold mb-4">组织架构</div>
        <Tree
          defaultExpandAll
          expandedKeys={expandedKeys}
          treeData={deptTree}
          showLine
          onSelect={(select) => {
            const deptId = select[0];
            onRefresh({ deptId });
          }}
        />
      </div>
      <div className="bg-white 2xl:w-3/4 xl:w-3/4 md:w-full lg:w-full sm:w-full w-full rounded-md p-4">
        <div className="text-base font-semibold mb-4">人员管理</div>
        <QueryTable.Provider
          name={QueryTableSystemUserName}
          requestFn={getUserTableList}
          params={{ deptId: null }}>
          <QueryTable.Filter schema={schema} components={{ Input, DatePicker }} />
          <QueryTable.Table
            columns={totalColumn}
            rowKey="userId"
            scroll={{ x: 1200 }}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            tabsNode={
              <Button icon={<AiOutlinePlus />} type="primary" onClick={setTrue}>
                新增人员
              </Button>
            }
            extraRightTool={
              <Button
                disabled={!selectedRowKeys.length}
                color="danger"
                icon={<AiOutlineDelete />}
                onClick={() => {
                  onDeleteUserByIds(selectedRowKeys as number[]);
                }}
                variant="outlined">
                批量删除
              </Button>
            }
          />
        </QueryTable.Provider>
      </div>
      <UserModal
        data={currentUser}
        deptList={deptTree}
        open={open}
        onOk={onRefresh}
        onClose={() => {
          setFalse();
          setCurrentUser(undefined);
        }}
      />
    </div>
  );
}
