import { useEffect, useRef, useState, useMemo } from 'react';
import { Drawer, Button, message } from 'antd';
import type { DrawerProps, TreeDataNode } from 'antd';
import { useBoolean, useMemoizedFn, useRequest } from 'ahooks';
import { Input, Select, Radio, Password, FormGrid, TreeSelect } from '@formily/antd-v5';
import { Field, onFieldReact, onFormMount } from '@formily/core';
import { ICreateUserReq, IUserItem } from 'src/api/types/system';
import { createUser, updateUser, getRoleList, getPostList } from 'src/api/clients/system';
import { Form, FormRef } from 'src/components/Form';
import { createSchema } from './schema';
import { omit } from 'lodash-es';

interface IProps extends DrawerProps {
  data?: IUserItem;
  deptList?: TreeDataNode[];
  onOk?: () => void;
}

export function UserModal(props: IProps) {
  const { data, deptList, open, onClose, onOk, ...extra } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const [formMounted, setFormMounted] = useState(false);
  const formRef = useRef<FormRef | null>(null);
  const isEdit = !!data?.userId;

  const onCloseHandle = (e: React.MouseEvent | React.KeyboardEvent) => {
    const formInstance = formRef.current?.getFormInstance();
    formInstance?.reset();
    onClose?.(e);
  };

  const onSubmit = useMemoizedFn(async (e) => {
    const formInstance = formRef.current?.getFormInstance();
    if (!formInstance) return;
    const values = await formInstance.submit<ICreateUserReq>();
    if (!values) return;
    setTrue();
    try {
      if (isEdit) {
        await updateUser({
          userId: data?.userId,
          ...omit(values, ['username']),
        });
        message.success('更新成功');
      } else {
        await createUser(values);
        message.success('创建成功');
      }
      onCloseHandle(e);
      onOk?.();
    } finally {
      setFalse();
    }
  });

  const { data: roleList } = useRequest(async () => {
    const res = await getRoleList({ offset: 0, limit: 10000 });
    return res.rows?.map((item) => ({ label: item.roleName, value: item.roleId }));
  });
  // 初始化请求，根据部门id获取岗位列表
  const { data: postList } = useRequest(
    async () => {
      if (!data?.deptId) return [];
      const res = await getPostList({ offset: 0, limit: 10000, deptId: data?.deptId });
      return res.rows?.map((item) => ({ label: item.postName, value: item.postId }));
    },
    { refreshDeps: [data?.deptId] },
  );
  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setFormMounted(true);
        });
        onFieldReact('postIds', async (field) => {
          const deptId = field.query('deptId').get('value');
          if (!deptId) {
            (field as Field).value = undefined;
            return;
          }
          (field as Field).loading = true;
          try {
            const res = await getPostList({ offset: 0, limit: 10000, deptId });
            const options = res.rows?.map((item) => ({ label: item.postName, value: item.postId }));
            if (field.componentProps) {
              field.componentProps.options = options;
            }
          } finally {
            (field as Field).loading = false;
          }
        });
      },
    };
  }, []);

  useEffect(() => {
    if (formMounted && open) {
      const formInstance = formRef.current?.getFormInstance();
      formInstance?.setFieldState('deptId', (field) => {
        if (field.componentProps) {
          field.componentProps.treeData = deptList;
        }
      });
      formInstance?.setFieldState('roleIds', (field) => {
        if (field.componentProps) {
          field.componentProps.options = roleList;
        }
      });
      formInstance?.setFieldState('postIds', (field) => {
        if (field.componentProps) {
          field.componentProps.options = postList;
        }
      });
      formInstance?.setFieldState('password', (field) => {
        field.visible = !isEdit;
      });
      formInstance?.setFieldState('username', (field) => {
        field.disabled = isEdit;
      });
    }
  }, [formMounted, deptList, open, roleList, postList]);

  useEffect(() => {
    const formInstance = formRef.current?.getFormInstance();
    const { username, phoneNumber, email, nickName, roles, deptId, posts, status, remark } =
      data ?? {};
    formInstance?.setValues({
      username,
      phoneNumber,
      email,
      nickName,
      roleIds: roles?.map((item) => item.id),
      deptId,
      postIds: posts?.map((item) => item.id),
      status,
      remark,
    });
  }, [data]);

  return (
    <Drawer
      open={open}
      title={isEdit ? '编辑用户' : '新增用户'}
      width={650}
      onClose={onCloseHandle}
      extra={
        <div className="flex gap-2">
          <Button loading={loading} type="primary" onClick={onSubmit}>
            {isEdit ? '更新' : '添加'}
          </Button>
        </div>
      }
      {...extra}>
      <Form
        hideAction
        ref={formRef}
        schema={createSchema}
        components={{ Input, Select, Radio, Password, FormGrid, TreeSelect }}
        layout={{ layout: 'vertical' }}
        formOptions={formOptions}
      />
    </Drawer>
  );
}
